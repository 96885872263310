<template>
  <div>
    <!-- <div class="section section-pd bg-pr-trans"> -->
    <!-- <div class="page-header">
        <div
          class="
            container
            shape-container
            d-flex
            align-items-center
            text-center-sm
          "
        >
          <div class="col px-0">
            <div class="row align-items-center justify-content-center">
              <div class="col-lg-5">
                <h1 class="text-dark display-1 f-bold text-head mb-3">
                  General Calculation
                </h1>
                <p class="f-16 text-dark mb-0">
                  Enter your energy consumption rate.
                </p>
                <div class="btn-wrapper mt-3 myhide">
                  <router-link
                    to="/energy-audit"
                    class="
                      btn btn-imp-secondary
                      bg-primary
                      text-white
                      btn-icon
                      mt-2
                      py-3
                      px-5
                    "
                  >
                    <span class="nav-link-inner--text"
                      >Request Energy Audits</span
                    >
                  </router-link>
                </div>
              </div>
              <div class="col-md-7 myhide">
                <div class="text-center">
                  <img
                    class="headerimg"
                    src="/assets/images/svgs/evaluation.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    <!-- </div> -->
    <div class="sectionPad section section-pd bg-pr-trans">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <div class="row">
              <div class="col-md-8">
                <div class="calHeading">
                  <img
                    class="mr-3"
                    src="/assets/images/svgs/archive-tick.svg"
                    alt="check"
                  />
                  <div>
                    <h2 class="">
                      <span class="text-capitalize">{{
                        calculation === "general" ? "General" : "Energy Load"
                      }}</span>
                      Calculation
                    </h2>
                    <p>
                      Please select <strong>only</strong> the appliances you want to power with the Solar
                      Home System
                    </p>
                  </div>
                </div>
              </div>
              <!-- <div class="col-md-5">
                <div class="text-right">
                  <div class="basedClass">
                    <div
                      @click="switchFormToGeneral(true)"
                      :class="{ active: calculation == 'general' }"
                    >
                      Generator Based
                    </div>
                    <div
                      @click="switchFormToGeneral(false)"
                      :class="{ active: calculation == 'device' }"
                    >
                      Device Based
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="formView mt-5">
              <div class="row justify-content-center">
                <div class="col-md-10">
                  <div class="row">
                    <div class="col-md-7">
                      <div class="yourCal">
                        <h5 class="f-semibold text-dark mb-3">
                          Your Calculation
                        </h5>
                        <form v-if="loaded" @submit.prevent="addAppliance()">
                          <div class="form-group calForm">
                            <label class="">Choose Device</label>
                            <select
                              name=""
                              class="form-control"
                              id=""
                              required=""
                              ref="applianceName"
                              @change="onDeviceItemChange($event)"
                            >
                              <option value="" disabled selected>
                                Choose a device
                              </option>
                              <option
                                v-for="(appliance, index) in deviceList"
                                :key="index"
                              >
                                {{ appliance.name }}
                              </option>
                            </select>
                          </div>
                          <div class="form-group calForm">
                            <label class="">Power rating</label>
                            <div class="inputDesc">
                              <input
                                type="number"
                                step="any"
                                class="form-control"
                                ref="capacityNum"
                                v-model="capcityOfSelectedDevice"
                                disabled
                                required
                              />
                              <span ref="powerMeasurment">KW</span>
                            </div>
                          </div>
                          <div class="form-group calForm">
                            <label class="">Quantity</label>
                            <input
                              class="form-control"
                              type="number"
                              ref="quantity"
                              oninput="javascript: if (this.value < 1) this.value = 1"
                              required
                            />
                          </div>
                          <div class="form-group calForm">
                            <label class="">Hours of usage daily</label>
                            <input
                              class="form-control"
                              type="number"
                              ref="dailyUsageHours"
                              maxValue="24"
                              oninput="javascript: if (this.value > 24) this.value = 24; else if (this.value < 1) this.value = 1"
                              required
                            />
                          </div>
                          <div class="form-group">
                            <button
                              class="
                                btn btn-imp-secondary
                                bg-primary
                                text-white
                                btn-block
                                mt-2
                                py-3
                                px-5
                              "
                              ref="buttonAddAppliance"
                            >
                              <span class="nav-link-inner--text">Add</span>
                            </button>
                          </div>
                        </form>
                        <loading v-else />
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="maintenn" v-if="!formGeneralIsActive">
                        <h5
                          class="f-semibold text-dark mb-3"
                          v-if="devices.length"
                        >
                          Your appliances
                        </h5>
                        <div
                          class="appliance"
                          v-for="(device, index) in devices"
                          :key="index"
                        >
                          <div>
                            <p class="name">{{ device.name }}</p>
                            <p class="descr">
                              {{ device.quantity }} | {{ device.size }} |
                              {{ device.on_time }}
                              hrs daily
                            </p>
                          </div>
                          <span @click="removeAppliance(index)">⨯</span>
                        </div>
                        <div v-if="devices.length" class="form-group">
                          <button
                            class="
                              btn btn-imp-secondary
                              bg-primary
                              text-white
                              btn-block
                              mt-2
                              py-3
                              px-5
                            "
                            @click="calculateCostAndEnergyConsumption()"
                          >
                            <span class="nav-link-inner--text">Get Result</span>
                            <BtnLoading
                              v-if="generatorCal"
                              class="btn-loading"
                            />
                          </button>
                          <!-- <p></p> -->
                        </div>
                        <div
                          id="calculator-result"
                          v-if="
                            calculation === 'device' && energyConsumption.length
                          "
                        >
                          <section class="result-container">
                            <!-- <h5 class="f-semibold text-dark mb-3">
                              Your Result
                            </h5> -->
                            <!-- <div
                              class="device-result"
                              v-if="calculation == 'device'"
                            >
                              <div class="left">
                                <div>
                                  <h3>{{ energyConsumption }}</h3>
                                  <p>
                                    Your estimated monthly energy consumption
                                  </p>
                                </div>
                              </div>
                            </div> -->
                            <VueHtml2pdf
                              :enable-download="true"
                              :preview-modal="false"
                              :paginate-elements-by-height="1400"
                              filename="Imperium Energy Calculation"
                              :pdf-quality="2"
                              :manual-pagination="false"
                              pdf-format="a3"
                              pdf-content-width="100%"
                              ref="html2Pdf"
                              :float-layout="true"
                              :show-layout="false"
                            >
                              <section slot="pdf-content">
                                <div class="pdf" ref="peedf">
                                  <div class="w-100">
                                    <img
                                      width="100"
                                      src="/assets/images/imperium_logo.png"
                                      alt="Logo"
                                    />
                                    <h1>ENERGY CALCULATION</h1>
                                  </div>
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>APPLICANCE</th>
                                        <th>QUANTITY</th>
                                        <th>UNIT RATING (Kw)</th>
                                        <th>LOAD (Kw)</th>
                                        <th>DURATION (Hr)</th>
                                        <th>KwH</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="(device, index) in devices"
                                        :key="index"
                                      >
                                        <td>{{ device.name }}</td>
                                        <td>{{ device.quantity }}</td>
                                        <td>{{ device.size }}</td>
                                        <td>
                                          {{
                                            (
                                              device.quantity * device.size
                                            ).toFixed(3)
                                          }}
                                        </td>
                                        <td>{{ device.on_time }}</td>
                                        <td>
                                          {{
                                            (
                                              device.quantity *
                                              device.size *
                                              device.on_time
                                            ).toFixed(3)
                                          }}
                                        </td>
                                      </tr>
                                      <tr></tr>

                                      <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th>TOTAL KwH</th>
                                        <th>{{ dailyConsumption }}</th>
                                      </tr>
                                      <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th>TOTAL Kw</th>
                                        <th>{{ totalLoad }}</th>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </section>
                            </VueHtml2pdf>
                            <span
                              @click="exportToPDF"
                              class="restart-calculator font-weight-bold"
                              >Download Calculation</span
                            >
                            <span> | </span>

                            <span
                              @click="resetCalculator()"
                              class="restart-calculator font-weight-bold"
                            >
                              Restart Calculator
                            </span>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BtnLoading from "@/components/BtnLoading.vue";
import VueHtml2pdf from "vue-html2pdf";
import Loading from "../components/App/Loading.vue";

export default {
  name: "Calculator",
  components: { BtnLoading, VueHtml2pdf, Loading },
  data() {
    return {
      loaded: false,
      categorySelect: "",
      loading: false,
      generatorCal: false,
      calculation: "device",
      formGeneralIsActive: false,
      fuelType: false,
      costOfFuelPerLitre: "",
      priceOfGenerator: null,
      monthlyMaintenanceCost: null,
      pertrol_per_litre: null,
      diesel_per_litre: null,
      energyConsumption: "",
      // showResult: false,
      appliance: {
        size: null,
        name: "",
        on_time: 0,
        quantity: 0,
      },

      devices: [],
      appliances: [],
      capcityOfSelectedDevice: null,

      generator: {
        size: null,
        cost_per_litre: null,
        type: "",
        on_time: null,
        // name: "generator",
        // quantity: 1,
        period_of_usage: null,
        load: 0.25,
      },
    };
  },
  mounted() {
    this.getAppliances();
    // this.getFuelCostPerLitre();
  },
  watch: {
    fuelType: function () {
      this.computeGeneratorPrice();
    },
  },
  computed: {
    costperlitre() {
      if (this.generalCal.type) {
        console.log(this.generalCal.type);
        if (this.generalCal.type == "petrol") {
          return `₦ ${this.prices.GASOLINE_PER_LITRE} / litre`;
        } else if (this.generalCal.type == "diesel") {
          return `₦ ${this.prices.DIESEL_PER_LITRE} / litre`;
        }
      } else {
        return ``;
      }
    },
    deviceList() {
      var returnArr = [];
      // if (this.appliances.length) {
      this.appliances.forEach((device) => {
        if (
          this.devices.find((dev) => dev.name === device.name) === undefined
        ) {
          returnArr.push(device);
        }
      });
      // }
      return returnArr;
    },
    dailyConsumption() {
      var total = 0;
      this.devices.forEach((device) => {
        total += device.quantity * device.size * device.on_time;
      });
      return total.toFixed(3);
    },
    totalLoad() {
      var total = 0;
      this.devices.forEach((device) => {
        total += device.quantity * device.size;
      });
      return total.toFixed(3);
    },
  },

  methods: {
    selectChange() {
      if (this.categorySelect === "complete") {
        this.$router.push("/categories?type=bundle");
      } else if (this.categorySelect === "special") {
        this.$router.push("/categories?type='package'");
      }
    },
    exportToPDF() {
      // console.log(VueHtml2pdf);
      this.$refs.html2Pdf.generatePdf();
    },
    computeGeneratorPrice() {
      const multiplier = this.fuelType === "diesel" ? 90000 : 30000;
      let genPrice = multiplier * this.generator.size;
      this.priceOfGenerator = genPrice.toLocaleString("NGN", {
        style: "currency",
        currency: "NGN",
      });
      this.priceOfGenerator =
        this.priceOfGenerator == "NGN 0.00" ? null : this.priceOfGenerator;
      this.monthlyMaintenanceCost = (0.015 * genPrice).toLocaleString("NGN", {
        style: "currency",
        currency: "NGN",
      });
      this.monthlyMaintenanceCost =
        this.monthlyMaintenanceCost == "NGN 0.00"
          ? null
          : this.monthlyMaintenanceCost;
    },
    getFuelCostPerLitre() {
      let payload = {
        path: `calculation/fuel-prices`,
      };
      this.$store
        .dispatch("getRequest", payload)
        .then((resp) => {
          let decr = JSON.parse(this.$helpers.decrypt(resp.data.data));
          resp.data = decr;
          // console.log(resp)
          this.loading = false;
          this.pertrol_per_litre = resp.data.data.GASOLINE_PER_LITRE;
          this.diesel_per_litre = resp.data.data.DIESEL_PER_LITRE;
        })
        .catch((err) => {
          if (err.response) {
            let decr = JSON.parse(
              this.$helpers.decrypt(err.response.data.data)
            );
            err.response.data = decr;
            this.$toast.info(
              "Price List",
              err.response.data.message,
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Price List",
              "Unable to get fuel price list, please try again",
              this.$toastPosition
            );
          }
          this.loading = false;
        });
    },
    getAppliances() {
      let payload = {
        path: `device`,
      };
      this.$store
        .dispatch("getRequest", payload)
        .then((resp) => {
          let decr = JSON.parse(this.$helpers.decrypt(resp.data.data));
          resp.data = decr;
          this.loading = false;

          this.appliances = resp.data.data;
          // console.log(this.appliances);
          this.loaded = true;
        })
        .catch((err) => {
          if (err.response) {
            let decr = JSON.parse(
              this.$helpers.decrypt(err.response.data.data)
            );
            err.response.data = decr;
            this.$toast.info(
              "Device List",
              err.response.data.message,
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Device List",
              "Unable to get all devices, please try again",
              this.$toastPosition
            );
          }
          this.loading = false;
          location.reload();
        });
    },
    onDeviceItemChange(event) {
      this.capcityOfSelectedDevice =
        this.deviceList[event.target.selectedIndex - 1].rating;
    },

    onChangeFuelType(event) {
      this.fuelType = event.target.value;
      if (this.fuelType === "diesel") {
        this.costOfFuelPerLitre = "₦ " + this.diesel_per_litre + " / litre";
      } else if (this.fuelType === "petrol") {
        this.costOfFuelPerLitre = "₦ " + this.pertrol_per_litre + " / litre";
      } else {
        this.costOfFuelPerLitre = "";
      }
      this.generator.type = this.fuelType === "diesel" ? "diesel" : "gasoline";
    },

    computeGeneratorPrice() {
      const multiplier = this.fuelType === "diesel" ? 90000 : 30000;
      let genPrice = multiplier * this.generator.size;

      this.priceOfGenerator = genPrice.toLocaleString("NGN", {
        style: "currency",
        currency: "NGN",
      });
      this.priceOfGenerator =
        this.priceOfGenerator == "NGN 0.00" ? null : this.priceOfGenerator;

      this.monthlyMaintenanceCost = (0.015 * genPrice).toLocaleString("NGN", {
        style: "currency",
        currency: "NGN",
      });
      this.monthlyMaintenanceCost =
        this.monthlyMaintenanceCost == "NGN 0.00"
          ? null
          : this.monthlyMaintenanceCost;
    },

    clearGeneratorInputField() {
      this.generator.size = null;
      this.generator.on_time = null;
      this.generator.period_of_usage = null;
    },

    clearMaintenanceInfo() {
      this.priceOfGenerator = null;
      this.monthlyMaintenanceCost = null;
      this.costOfFuelPerLitre = "";
    },

    clearApplianceInputFields() {
      this.$refs.applianceName.selectedIndex = 0;
      this.$refs.capacityNum.value = 0;
      this.$refs.powerMeasurment.value = 0;
      this.$refs.quantity.value = "";
      this.$refs.dailyUsageHours.value = "";
      this.capcityOfSelectedDevice = null;
    },

    clearAddedAppliance() {
      this.devices = [];
    },

    switchFormToGeneral(val) {
      this.formGeneralIsActive = val;

      if (this.formGeneralIsActive) {
        this.calculation = "general";
        this.clearApplianceInputFields();
        this.clearAddedAppliance();
      } else {
        this.calculation = "device";
        this.clearGeneratorInputField();
        this.clearMaintenanceInfo();
      }
    },

    removeAppliance(index) {
      this.devices.splice(index, 1);
    },

    addAppliance() {
      let appliance = this.$refs.applianceName;
      let name = appliance.options[appliance.selectedIndex].value;
      let size = parseFloat(this.$refs.capacityNum.value);
      let quantity = parseInt(this.$refs.quantity.value);
      let on_time = parseInt(this.$refs.dailyUsageHours.value);
      if (quantity <= 0) {
        this.$toast.info(
          "Info",
          "Quantity must be 1 or more",
          this.$toastPosition
        );
        return false;
      } else if (on_time <= 0) {
        this.$toast.info(
          "Info",
          "Daily usage must be 1 hour or more",
          this.$toastPosition
        );
        return false;
      } else {
        this.appliance = { size, name, on_time, quantity };
        this.devices.push(this.appliance);
        this.clearApplianceInputFields();
      }
    },

    onDieselPercentageLoadingChange(e) {
      this.generator.load = e.target.value / 100;
    },

    calculateCostAndEnergyConsumption() {
      // if (this.formGeneralIsActive) {
      //   localStorage.calculationType = "genrator-based";

      //   this.generatorCal = true;

      //   let data = this.generator;
      //   data = this.$helpers.encrypt(JSON.stringify(data));

      //   data = {
      //     data,
      //   };
      //   let payload = {
      //     data,
      //     path: "/calculation/generator",
      //   };

      //   this.$store
      //     .dispatch("postRequest", payload)
      //     .then(({ data }) => {
      //       let decr = JSON.parse(this.$helpers.decrypt(data.data));
      //       data = decr;
      //       this.generatorCal = false;
      //       localStorage.monthlyMaintenanceCost =
      //         data.data.monthlyMaintenanceCost;
      //       localStorage.yearlyMaintenanceCost =
      //         data.data.yearlyMaintenanceCost;
      //       localStorage.totalMaintenanceCost = data.data.totalMaintenanceCost;

      //       localStorage.fuelCostPerWeek = data.data.fuelCostPerWeek;
      //       localStorage.fuelCostPerMonth = data.data.fuelCostPerMonth;
      //       localStorage.fuelCostPerYear = data.data.fuelCostPerYear;

      //       this.$router.push("/calculator-result");

      //       this.$toast.success(
      //         "Result Generation",
      //         "Result generated successfully",
      //         this.$toastPosition
      //       );
      //     })
      //     .catch((err) => {
      //       if (err.response) {
      //         let decr = JSON.parse(
      //           this.$helpers.decrypt(err.response.data.data)
      //         );
      //         err.response.data = decr;
      //         this.$toast.info(
      //           "Result Generation",
      //           err.response.data.message,
      //           this.$toastPosition
      //         );
      //       } else {
      //         this.$toast.info(
      //           "Result Generation",
      //           "Unable to generate result, check the details and try again",
      //           this.$toastPosition
      //         );
      //       }
      //       this.generatorCal = false;
      //     });
      // } else {
      if (this.devices.length === 0) {
        this.$toast.info(
          "Info",
          "Please add devices to proceed",
          this.$toastPosition
        );

        return;
      } else {
        localStorage.calculationType = "device-based";
        this.generatorCal = true;

        let data = { devices: this.devices };
        // console.log(data);
        // return;
        data = this.$helpers.encrypt(JSON.stringify(data));

        data = {
          data,
        };
        let payload = {
          data,

          // let payload = {
          //   data: { devices: this.devices },
          path: "/calculation/device",
        };

        this.$store
          .dispatch("postRequest", payload)
          .then(({ data }) => {
            // console.log(data);
            let decr = JSON.parse(this.$helpers.decrypt(data.data));
            data = decr;

            this.generatorCal = false;
            // console.log(data);
            this.energyConsumption = data.data.energyConsumptionPerMonth;
            // localStorage.energyConsumptionPerWeek =
            //   data.data.energyConsumptionPerWeek;
            // localStorage.energyConsumptionPerYear =
            //   data.data.energyConsumptionPerYear;
            // localStorage.devices = JSON.stringify(this.devices);

            // this.$router.push("/calculator-result");

            this.$toast.success(
              "Result Generation",
              "Result generated successfully",
              this.$toastPosition
            );
            // this.showResult = true;
            setTimeout(() => {
              this.exportToPDF();
            }, 1500);
          })
          .catch((err) => {
            if (err.response) {
              let decr = JSON.parse(
                this.$helpers.decrypt(err.response.data.data)
              );

              err.response.data = decr;
              this.$toast.info(
                "Result Generation",
                err.response.data.message,
                this.$toastPosition
              );
            } else {
              // console.log(err);
              this.$toast.info(
                "Result Generation",
                "Unable to generate result, check the details and try again",
                this.$toastPosition
              );
            }
            this.generatorCal = false;
          });
      }
      // }
    },
    resetCalculator() {
      this.categorySelect = "";
      this.loading = false;
      this.generatorCal = false;
      this.calculation = "device";
      this.formGeneralIsActive = false;
      this.fuelType = false;
      this.costOfFuelPerLitre = "";
      this.priceOfGenerator = null;
      this.monthlyMaintenanceCost = null;
      this.pertrol_per_litre = null;
      this.diesel_per_litre = null;
      this.energyConsumption = "";
      this.appliance = {
        size: null,
        name: "",
        on_time: 0,
        quantity: 0,
      };

      this.devices = [];
      // this.appliances= [];
      this.capcityOfSelectedDevice = null;

      this.generator = {
        size: null,
        cost_per_litre: null,
        type: "",
        on_time: null,
        // name: "generator",
        // quantity: 1,
        period_of_usage: null,
        load: 0.25,
      };
    },
  },
};
</script>

<style lang="scss">
.calHeading {
  display: flex;
  align-items: flex-start;
  h2 {
    font-weight: bold;
    font-size: 32.256px;
    color: #2b2b2b;
    display: inline-block;
    margin-bottom: 12px;
    line-height: 1;
  }
}
.basedClass {
  background: #e0ecce;
  border-radius: 19px;
  display: inline-block;
  div {
    display: inline-block;
    font-weight: 500;
    font-size: 14.9652px;
    color: #255e13;
    padding: 7px 32px;
    line-height: 51px;
    cursor: pointer;
    &.active {
      background-color: #219653;
      border-radius: 19px;
      color: white;
    }
  }
}
.appliance {
  color: black;
  background: #0000000a;
  padding: 16px;
  margin-bottom: 19px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  span {
    font-size: 31px;
    padding: 1px 7px;
    cursor: pointer;
  }
  .name {
    margin: 0;
    font-weight: bold;
  }
  .descr {
    margin: 0;
    font-size: 14px;
    opacity: 0.6;
  }
}
.bg-prp {
  background: #f5fcf2;
}
.myshow {
  display: none;
}
@media only screen and (max-width: 991px) {
  .myshow {
    display: block;
  }
  .myhide {
    display: none !important;
  }
  .mra {
    margin: auto;
    margin-bottom: 30px;
    margin-top: 67px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 991px) {
  .mra {
    margin-top: 4px !important;
  }
}
@media only screen and (max-width: 768px) {
  .headerimg {
    width: 72%;
    padding-top: 60px;
  }
  .basedClass {
    margin-top: 20px;
  }
  .formView {
    margin-top: 0px !important;
    background: transparent;
  }
  .maintenn {
    margin-top: 45px;
  }
  .yourCal {
    padding: 0px;
    border: none;
  }
}
.category-select {
  select {
    option {
      color: black;
    }
  }
}
</style>
<style lang="scss" scoped>
.yourCal {
  height: 100%;
}
.formView {
  background-color: #fff;
}
#calculator-result {
  animation-name: fadeIn;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 1s;
  animation-fill-mode: forwards;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .result-container {
    margin-top: 4rem;

    h2 {
      font-size: 2.4rem;
      font-weight: bold;
      color: #1d1d1d;
    }

    .device-result {
      h5 {
        text-align: left;
      }
      /* display: grid; */
      /* grid-template-columns: 3fr 1fr; */
      /* column-gap: 3rem; */
      margin-bottom: 1.6rem;

      @media screen and (max-width: 680px) {
        grid-template-columns: 1fr;
      }

      .left {
        border-radius: 18px;
        background: white;

        div {
          text-align: center;
          padding: 2rem 0;

          h3 {
            font-size: 1.4rem;
            font-weight: bold;
            color: black;
            margin-block-start: 0;
            margin-block-end: 0;
          }

          p {
            color: #00000094;
            margin-top: 0.6rem;
            margin-bottom: 0;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 12px;
            letter-spacing: 1px;
          }
        }
      }

      .right {
        position: relative;
        display: flex;
        justify-content: center;
        align-content: center;
        border-radius: 18px;
        background: white;

        @media screen and (max-width: 680px) {
          padding: 1rem 0;
          margin-top: 1.6rem;
        }

        img {
          position: absolute;
          top: 4.4rem;
          z-index: -10;

          @media screen and (max-width: 680px) {
            top: 2rem;
          }
        }

        select {
          outline: 0;
          border: 0px;
          text-align-last: center;
          font-size: 1.2rem;
        }
      }
    }

    .gen-result {
      display: grid;
      grid-template-columns: 3fr 1fr;
      column-gap: 3rem;
      margin-bottom: 1.6rem;

      @media screen and (max-width: 900px) {
        grid-template-columns: 1fr;
      }

      .left {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        border-radius: 18px;
        background: white;

        @media screen and (max-width: 900px) {
          grid-template-columns: 1fr 1fr;
        }

        div {
          text-align: center;
          padding: 2rem 0;

          h3 {
            font-size: 1.4rem;
            font-weight: bold;
            color: black;
            margin-block-start: 0;
            margin-block-end: 0;
          }

          p {
            color: #00000094;
            margin-top: 0.6rem;
            margin-bottom: 0;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 12px;
            letter-spacing: 1px;
          }

          &:nth-child(2) {
            border-left: 1px solid #1d1d1d1c;
            border-right: 1px solid #1d1d1d1c;

            @media screen and (max-width: 900px) {
              border-right: none;
            }
          }

          &:nth-child(3) {
            @media screen and (max-width: 900px) {
              grid-column-start: 1;
              grid-column-end: 3;
              border-top: solid 1px #1d1d1d;
            }
          }
        }
      }

      .right {
        position: relative;
        display: flex;
        justify-content: center;
        align-content: center;
        border-radius: 18px;
        background: white;

        @media screen and (max-width: 900px) {
          padding: 1rem 0;
          margin-top: 1.6rem;
        }

        img {
          position: absolute;
          top: 4.4rem;
          z-index: -10;

          @media screen and (max-width: 900px) {
            top: 2rem;
          }
        }

        select {
          outline: 0;
          border: 0px;
          text-align-last: center;
          font-size: 1.2rem;
        }
      }
    }

    .restart-calculator {
      text-decoration: none;
      color: #59a358;
      cursor: pointer;
    }
  }

  .products {
    margin-top: 5rem;

    h2 {
      font-size: 1.4rem;
      font-weight: 500;
    }
    .products-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      border: solid 1px #000000;
      border-right: none;
      border-bottom: none;

      @media screen and (max-width: 900px) {
        grid-template-columns: repeat(2, 1fr);
        margin-top: 3rem;
      }

      @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
      }

      .product-item {
        display: flex;
        justify-content: center;
        border-right: solid 1px #000000;
        border-bottom: solid 1px black;
        padding-top: 2.125rem;
        cursor: pointer;

        .centered-content {
          width: 85%;

          @media screen and (max-width: 900px) {
            width: 80%;
          }

          animation-name: fadeIn;
          animation-iteration-count: 1;
          animation-timing-function: ease-in-out;
          animation-duration: 1s;
          animation-fill-mode: forwards;

          @keyframes fadeIn {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }

          .img-container {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            height: 15rem;
            padding-bottom: 1.8rem;

            @media screen and (max-width: 900px) {
              height: 10rem;
            }

            img {
              width: 60%;
              position: relative;
              object-position: bottom;
              object-fit: contain;
              max-height: 100%;

              @media screen and (max-width: 900px) {
                width: 60%;
              }
            }
          }

          .product-name {
            font-size: 1.1rem;
            font-weight: 500;
            color: #000000;
            margin-top: 1.5rem;
            margin-bottom: 0.2rem;
            overflow: hidden;
          }

          .product-description {
            font-size: 0.9rem;
            color: #000000;
            font-weight: 500;
            margin-bottom: 0.5rem;
            line-height: 1.4rem;
            opacity: 0.8;
            overflow: hidden;
          }

          .price {
            font-size: 1.125rem;
            color: #000000;
            font-weight: 500;
            margin-bottom: 1.4rem;
            line-height: 1.4rem;
          }
        }
      }
    }
  }
}
.pdf-content {
  width: 100%;
}
.pdf {
  background-color: #fff;
  padding: 32px;
  h1 {
    text-align: center;
    margin-bottom: 24px;
  }
  table {
    width: 100%;
    tr {
      td,
      th {
        padding: 5px;
      }
    }
  }
}
</style>